import React, { useState, useEffect } from "react"

import Meta from '../components/meta';

import { Link } from '@reach/router'

import PrevNext from '../components/prevNext';

import Moment from 'react-moment'

import axios from 'axios'

import Contents from '../components/contents'



const NoticeSingle = (props) => {

    let refUrl

    if(props.location.state === null){
        refUrl = ""
    }else{
        refUrl = props.location.state.refUrl
    }

    const postId = props.userId

    const [postData, setPostData] = useState('')
    const [postContent, setContent] = useState('')
    const [nextData, setNextData] = useState(null)
    const [prevData, setPrevData] = useState(null)



    useEffect(() =>{

        let isMounted = true

        async function getPostData(){
            const res = await axios.get(process.env.API_URL + `/news/${postId}`, { 
                headers: {
                    'Content-type': 'application/json',
                    'X-API-KEY': process.env.API_KEY
                },
            })
            
            const post = res.data

            // console.log(post.postDate)

            if (isMounted) {
                setPostData(post)

                if(post.content){
                    setContent(post.content)
                }else{
                    setContent(post.import)
                }
            }

            const resNext = await axios.get(process.env.API_URL + `/news/` +
            '?limit=1' + 
            '&orders=postDate' + 
            '&filters=postDate[greater_than]' + post.postDate, {
                headers: {
                    'Content-type': 'application/json',
                    'X-API-KEY': process.env.API_KEY
                },
            })
            if ( resNext.data.contents && resNext.data.contents.length > 0 ) {
                const next = resNext.data.contents[0]
                if(next.id){

                    if (isMounted) {
                        setNextData(next)
                    }
                }
            }else{

                if (isMounted) {
                    setNextData(null)
                }
            }

            const resPrev = await axios.get(process.env.API_URL + `/news/` +
            '?limit=1' + 
            '&orders=-postDate' + 
            '&filters=postDate[less_than]' + post.postDate, {
                headers: {
                    'Content-type': 'application/json',
                    'X-API-KEY': process.env.API_KEY
                },
            })
            if ( resPrev.data.contents && resPrev.data.contents.length > 0 ) {
                const prev = resPrev.data.contents[0]
                if(prev.id){

                    if (isMounted) {
                        setPrevData(prev)
                    }
                }
            }else{

                if (isMounted) {
                    setPrevData(null)
                }
            }

        }

        getPostData()

        return () => { isMounted = false };

    },[postId]);



    return(
        <>

        <Meta
            title={postData.title}
            description={postContent.replace(/\s|&nbsp;|\r?\n|<("[^"]*"|'[^']*'|[^'">])*>/g,'')}
        />

        <Contents>
            <div className="c-sub-header">
                <em className="c-sub-header--eng u-josef">

                </em>

                <h1 className="c-sub-header--title is-notice is-single">
                {postData.title}
                </h1>
            </div>



            <div className="p-news-detail">
                <div className="p-news-detail-inner">

                    <div className="p-news-detail-nav">
                        <div className="p-news-detail-nav__data">
                            <p className="p-news-detail-nav__data--date">
                            <Moment format="YYYY.MM.DD">{postData.postDate}</Moment>
                            </p>
                        </div>

                        <PrevNext prev={prevData} next={nextData} slug='/notice/' name="お知らせ一覧" refUrl={refUrl} />
                    </div>



                    <div className="p-news-detail-desc">
                        <div className="p-news-detail-desc--text">
                            
                            <div
                            dangerouslySetInnerHTML={{ __html:postContent.replace(/\r?\n/g, '<br/>')}}
                            />

                        </div>

                        {(() =>{
                            if(refUrl){
                                return(
                                    <>

                                    <div className="p-news-detail-desc-cmd u-align-center">
                                        <Link to={refUrl} className="p-news-detail-desc-cmd--btn">戻る</Link>
                                    </div>

                                    </>
                                )
                            }
                        })()}

                    </div>

{/* {(() =>{
    if(postData.images){
        return(
            <>

                {postData.images.map((val,index) => (
                    <img src={val.multi_image_item.url} alt="" key={index} />
                ))}

            </>
        )
    }
})()} */}

                </div>
            </div>
        </Contents>
        </>
    )
}

export default NoticeSingle


