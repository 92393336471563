import React, { useState, useEffect } from "react"

import Meta from '../components/meta';

import { Router } from '@reach/router'

import NoticeArchive from '../templates/noticeArchive'
import NoticeArchives from '../templates/noticeArchives'
import NoticeSingle from '../templates/noticeSingle'

import Contents from '../components/contents'



const Notice = (props) =>{

    const [allPostData, setAllPostData] = useState('');

    useEffect(() =>{
        const postList = sessionStorage.getItem('postData')

        if(postList !== 'is-error'){
            const setList = JSON.parse(postList)
            setAllPostData(setList)
        }

        // console.log(setList)
        // console.log(allPostData)
    },[])



    return(
        <>

            <Meta
                title="ニュース"
                description="アクロスソリューションズのニュースを掲載します。"
            />

            {(() =>{
                if(allPostData !== ''){

                    return(
                        <Router basepath={props.uri}>
                            <NoticeArchive path="/" list={allPostData} />
                            <NoticeArchives path="/archive/:year" list={allPostData} />
                            <NoticeSingle path=":userId" list={allPostData} />
                        </Router>
                    )
                }else{
                    return(
                        <>
                            <Contents>
                                <div className="c-sub-header">
                                    <em className="c-sub-header--eng u-josef">
                                    NEWS ARCHIVE
                                    </em>

                                    <h1 className="c-sub-header--title">
                                    ニュース notice
                                    </h1>
                                </div>



                                <div className="p-news-archive">
                                    <div className="p-news-archive-inner">

                                        <p>
                                            現在準備中です。次回リリースまでお待ちください。
                                        </p>

                                    </div>
                                </div>
                            </Contents>
                        </>
                    )
                }
            })()}
        </>
    )
}

export default Notice


