import React from "react"

import { Link } from 'gatsby';



const _link = (direction,name,refUrl) =>{
    return(
        <>
            <Link to={`/notice/${direction.id}`} state={{refUrl:`${refUrl}`}}>
            {(() => {
                if(name === 'prev'){
                    return(
                        <>
                        <i className="fa fa-angle-left mr"></i>PREV
                        </>
                    )
                }else{
                    return(
                        <>
                        NEXT<i className="fa fa-angle-right ml"></i>
                        </>
                    )
                }
            })()}
            </Link>
        </>
    )
}

const PrevNext = ({ prev, next, slug, name, refUrl}) => {

    return(
        <>
        <div className="p-news-detail-nav__cmd">
            <div className="p-news-detail-nav__cmd-prev u-josef">
            { prev === null ? null : _link(prev, `prev`, refUrl)}
            </div>

            <div className="p-news-detail-nav__cmd-all u-josef">
                <Link to={`${slug}`} title={name}>
                    ALL
                </Link>
            </div>

            <div className="p-news-detail-nav__cmd-next u-josef">
                { next === null ? null : _link(next, `next`, refUrl)}
            </div>
        </div>
        </>
    )
}
export default PrevNext


