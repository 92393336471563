import React from "react"
import { Link } from '@reach/router'
import { navigate } from 'gatsby';

import moment from 'moment'
import Moment from 'react-moment'

import Contents from '../components/contents'



const NoticeArchives = (props) => {

    let returnUrl = props.location.pathname

    const year = props.year
    let list = props.list



    let setYear = [];
    let tmpDate

    let tmpYear
    let nowYear

    tmpYear = moment(new Date())
    nowYear = tmpYear.format('YYYY')

    list.forEach(element => {

        tmpDate = moment(element.postDate)
        let tmpChkYear = tmpDate.format('YYYY')

        if(tmpChkYear <= nowYear){
            setYear.push(tmpDate.format('YYYY'))
        }
    })

    let chkSelectDate = year

    const setYears = new Set(setYear);
    const uniqueYear = Array.from(setYears)

    const selectYear = () =>{
        const sYear = document.getElementById('selectYear');
        const sYearNum = sYear.selectedIndex;

        navigate(`/notice/archive/${sYear.options[sYearNum].value}/`);
    }

    let newsData = [];

    let targetPostId = [];

    list.forEach((val) => {

        let tmpChkYear
        tmpChkYear = moment(val.postDate)
        let chkYear = tmpChkYear.format('YYYY')

        if(chkYear === year){

            let setPostDate
            setPostDate = moment(val.postDate)
            let arrPostDate = setPostDate.format('YYYY.MM')
            let arrMonth = setPostDate.format('MM')
    
            targetPostId.push([arrMonth,{yearmonth:arrPostDate,month:arrMonth},
                {id:val.id,defDate:val.postDate,postDate:arrPostDate,title:val.title,content:val.content}
            ])
        }
    })

    newsData = targetPostId
    newsData.sort(function(a,b){return(b[0] - a[0]);});



    function getMonth(yearMonth){
        let year = yearMonth.slice(0,4);
        let month = yearMonth.slice(5,7);
        var yearStr = year;
        var monthStr = month;
        var jsMonth = monthStr - 1 ;

        const monthName = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        var date = new Date( yearStr, jsMonth);

        return monthName[date.getMonth()];
    }



    return(
        <>
        <Contents>
            <div className="c-sub-header">
                <em className="c-sub-header--eng is-sp u-josef">
                NEWS <br />ARCHIVE
                </em>

                <h1 className="c-sub-header--title">
                ニュース
                </h1>
            </div>



            <div className="p-news-archive">
                <div className="p-news-archive-inner">

                    {(() =>{
                        if(newsData.length){
                            return(
                                <>
                                    <div className="p-news-archive__selector">
                                        <label className="c-select__cursor">
                                            <select id="selectYear" className="c-select p-news-archive__selector--box" onBlur={selectYear} onChange={selectYear} defaultValue={chkSelectDate}>

                                            {uniqueYear.map((val,index) => (
                                                <option key={index} value={val}>{val}</option>
                                            ))}

                                            </select>

                                        </label>
                                    </div>
                                </>
                            )
                        }
                    })()}



                    {(() =>{
                        if(newsData.length){
                            let loopDate;

                            return(
                                <>
                                    {newsData.map((val,index) => {
                                        if(loopDate !== val[0]){

                                            loopDate = val[0];

                                            return(
                                            <React.Fragment key={index}>
                                            <div className="p-news-archive-box">
                                                <div className="p-news-archive-title">
                                                    <h2 className="p-news-archive-title--text">
                                                        <span className='p-news-archive-title--date'>
                                                            {val[1].yearmonth}

                                                            <span className='c-small'>
                                                            {getMonth(val[1].yearmonth)}
                                                            </span>
                                                        </span>
                                                    </h2>
                                                </div>

                                                <div className="p-news-list">
                                                    {newsData.map((vals,index) =>{
                                                        if(val[1].yearmonth === vals[2].postDate){
                                                            return(
                                                            <React.Fragment key={index}>
                                                                <div className="p-news-list__item">
                                                                    <div className="p-news-list__item-date">
                                                                            <span className="p-news-list__item-date--text">
                                                                            <Moment format="YYYY.MM.DD">{vals[2].defDate}</Moment>
                                                                            </span>
                                                                    </div>

                                                                    <div className="p-news-list__item-title">
                                                                        <Link to={`/notice/${vals[2].id}`} title={vals[2].title} className="p-news-list__item-title--link" state={{refUrl:`${returnUrl}`}}>
                                                                        {vals[2].title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            )
                                                        }else{
                                                            return false
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                            </React.Fragment>
                                            )
                                        }
                                    })}
                                </>
                            )
                        }else{
                            return(
                                <>

                                    <p>
                                        現在準備中です。次回リリースまでお待ちください。
                                    </p>

                                </>
                            )
                        }
                    })()}

                </div>
            </div>
        </Contents>
        </>
    )
}

export default NoticeArchives


